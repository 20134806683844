// Placeholder to allow overriding predefined variables smoothly.

// Define fonts
$brand-font: "Yaldevi", sans-serif;
$headline-font: "Source Serif Pro", serif;
$base-font: "IBM Plex Sans", "Helvetica", "Arial", sans-serif;

// Colors
$logo-color: #ffffff;
$title-color: #213547;
$brand-color: #626262;
$link-color: #005b99;

$background-color: #fdfdfd;
$header-background: #f3f2f2;
$footer-background: #f9f9f9;
$code-background: #f5f2f0;
$border-line: #e7e7e7;

$button-dark: #395c7b;
$button-grey: #f1f1f1;
$button-olive: #42b883;
$button-blue: #1565C0;
$button-green: #2bb24c;
$button-orange: #ff5500;
$button-purple: #727fbd;
$button-teal: #007C89;
$button-dark-hover: lighten($button-dark, 8%);
$button-grey-hover: darken($button-grey, 8%);
$button-olive-hover: darken($button-olive, 8%);
$button-blue-hover: darken($button-blue, 8%);
$button-green-hover: darken($button-green, 8%);
$button-orange-hover: darken($button-orange, 8%);
$button-purple-hover: darken($button-purple, 8%);
$button-teal-hover: darken($button-teal, 8%);

// Define spacing
$spacing-half:     15px;
$spacing-third:	   10px;

// Define radius
$base-radius: 6px;
$narrow-radius: 4px;

// Width of the content area
$content-width:    810px;
$on-laptop:        810px;
