// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

@import 'fonts';

// Site header
.site-header {
  border-top: 0;
  border-bottom: 1px solid $border-line;
  background: $header-background;
  color: $brand-color;
}

.site-title {
  @include relative-font-size(1.5);
  color: $background-color;
  text-shadow: 0.8px 0.8px 0 #555;
  position: relative;
  float: left;
  top: 10px;
  color: $logo-color !important;
  font-family: $brand-font;
  letter-spacing: -1px;
  line-height: 1.4;
  width: 48px;
  height: 32px;
  border-radius: 14px;
  padding: 0 4px 4px;
  display: flex;
  justify-content: center;
  background: conic-gradient(
    #CA4246 16.666%,
    #E16541 16.666% 33.333%,
    #F18F43 33.333% 50%,
    #8B9862 50% 66.666%,
    #476098 66.666% 83.333%,
    #A7489B 83.333%);
    &:before {
    content: "<rb>";
    }
    &:hover {
    text-decoration: none;
    color: $logo-color !important;
    }
    @include media-query($on-palm) {
    top: 10px;
    padding: 0 4px 4px;
    }
}

.page-link {
    color: $brand-color !important;
  }

// Basic styling
body {
  font-weight: $base-font-weight;
  font-family: $base-font;
}

:hover {
  transition-property: all;
  transition-duration: 650ms;
  transition-timing-function: ease;
  transition-delay: 0s;
}

a {
  color: $link-color !important;
  text-decoration: none;

  &:hover {
    color: $brand-color-dark !important;
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}

.bold {
  font-weight: 600;
}

.post-content a[href^="https://"]:not([href*="belenos.me"]):after, .note-content a[href^="https://"]:not([href*="belenos.me"]):after {
     content: " " url('/assets/img/external.avif');    
}

.share-bar a, .social-page li a[href^="https://"]:not([href*="belenos.me"]):after {
     content: "";    
}

hr {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid $border-line;
}

// Tables
table {
  margin-bottom: $spacing-unit!important;
  table-layout: fixed;
  width: 100% ;
  text-align: center;
  color: $table-text-color;
  border-collapse: collapse;
  border: 0;
  tr {
    &:nth-child(even) {
      background-color: $table-zebra-color;
    }
  }
  th {
    background-color: $table-header-bg-color;
    border: 1px solid $button-grey;
  }
  td {
    border: 1px solid $button-grey;
    width: 25% ;
  }
}

// Headings
h1 {
  font-weight: $base-font-weight;
  font-family: $headline-font;
  color: $title-color;
}

h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
}

@media screen and (max-width: $on-palm) {
  h1 { font-size: $base-font-size * 2!important; }
  h2 { font-size: $base-font-size * 1.5!important; }
}

.page-heading {
     margin: 0 0 $spacing-unit 0;
     text-align: center;
}

// Page Content
.page-centered {
  text-align: center;
}

// Post Content
.post-content {
  max-width: $on-laptop;
  margin: 0 auto;
     @include media-query($on-laptop) {
       max-width: 100%;
       }
     span, p, h2, h3, h4, form, ul, ol, table, .highlighter-rouge, .share-bar, .year-list, #results-container {
       max-width: $on-palm;
       margin: 0 auto;
       }
     p {
       padding-bottom: $spacing-half;
       }
     ol, ul { 
       padding: 0 0 $spacing-half 0;
         & li {
           margin-left: $spacing-unit;
           }
       }
     h2, h3, h4 {
       padding: $spacing-half 0;
       }
     .image-caption {
       margin-top: -10px;
       margin-bottom: $spacing-half;
       }
     .related-posts li, .post-list li, #results-container li {
       margin-left: 0;
       }
     .post-list {
       padding-bottom: 0;
       }
}

.post-tags {
  border-bottom: 1px solid $border-line;
  padding-bottom: $spacing-unit !important;
}

// Images & Videos
img {
  border-radius: $base-radius;
}

figure {
  position: relative;
  margin-bottom: $spacing-unit;
}

figure.content-image {
  margin-top: $spacing-half;
  object-fit: cover;
}

.post-content figure {
  aspect-ratio: 16 / 9;
}

figure img {
  margin-bottom: $spacing-half;
  max-width: 100%;
  height: auto;
}

figcaption {
  display: block;
  position: absolute;
  max-width: 100%;
}

figcaption.credits {
  bottom: 5px;
  right: 3px;
  padding: 2px 6px;
  background: rgba(64, 64, 64, 0.7);
  color: $brand-color-light;
  font-size: $base-font-size * 0.8;
  box-sizing: border-box;
  border-radius: $narrow-radius;
}

figcaption.caption {
  font-size: $base-font-size * 0.85;
  margin-top: -12px;
  color: $brand-color;
}

.page-image { 
  margin: $spacing-half 0 0 0;
  max-width: 100%;
  height: auto;
}

.page-thumb { 
  max-width: 100%;
  height: auto;
}

.full-width {
  display: inline;
  margin-bottom: $spacing-unit * 1.6;
}

.space-full-width {
  line-height: 1;
  color: transparent;
}

.vertical-spacing {
  display: inline;
  margin-bottom: $spacing-unit;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.post-content video { 
  margin: $spacing-half 0;
  max-width: 100%;
  height: auto;
}

// Code formatting
pre,
code {
  font-family: $code-font-family;
  border: 0;
  border-radius: $base-radius;
  background-color: $code-background;
}

.highlight {
  border-radius: $base-radius;
  background: $code-background;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: $code-background;
  }
}

// YouTube Embed
.video-embed {
  position: relative;
  padding-bottom: 56.25%;
  margin: $spacing-unit 0;
  height: 0;
  overflow: hidden;
  
    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

// Post Meta
.post-tag-top {
  position: relative;
  display: inline;
  bottom: $spacing-third;
}
.p-author {
  font-weight: 600;
}

a.social-meta {
  padding: 2px 8px;
  margin: 0;
}

.meta-avatar {
  position: relative;
  display: inline;
  float: left;
  bottom: -3px;
  margin-right: 16px;
    & img {
      border-radius: 50%;
      }
}

// Posts lists
.post-list {
	list-style-type: none;
	& li {
	margin-bottom: $spacing-half;
	}
}

.description {
 margin-bottom: ($spacing-unit * 1.4)!important; 
}

// Pagination
.pager {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacing-unit;
  }

// Share Bar
.share-bar {
  max-width: $on-palm;
  margin: 0 auto;
  border-bottom: 1px solid $border-line;
  display: flex;
  justify-content: space-between;
}

.share-bar a {
  margin: 8px;
  	&:first-child {
	margin-left: 12px; }
	&:last-child {
	margin-right: 12px; }
}

.share-bar a span {
  padding: 8px;
  display: inline-block;
  	&:hover {
	color: $background-color; }
}

.fa, .fab, .fas { color: $brand-color; border-radius: $base-radius; }
.fa-link:hover { background-color: $link-color; }
.fa-x-twitter:hover { background-color: #55acee; }
.fa-bluesky:hover { background-color: #0085ff; }
.fa-facebook:hover { background-color: #3b5998; }
.fa-reddit-alien:hover { background-color: #ff4500; }
.fa-whatsapp:hover { background-color: #25D366; }
.fa-envelope:hover { background-color: #BB001B; }

// Follow Button
.follow-button {
  position: relative;
  float: right;
  bottom: 10px;
  display: inline-block;
}

.social-page {
  list-style-type: none;
  	li {
  	margin-bottom: 12px; }
}

.avatar-large {
  border-radius: 50%;
}
  
// Related Posts
.related-title {
    @include relative-font-size(1.125);
    @media screen and (min-width: $on-palm) {
      @include relative-font-size(1.25);
    }
  }

.related-posts {
  list-style-type: none;
  padding: $spacing-unit 0!important;
    & li {
        margin-top: $spacing-third;
      }
  }

// Archive, Topics & Search
.date-left {
	width: 64px;
	float: left;
	color: $brand-color;
	}

.search-date {
	color: $brand-color;
	}

.tag-page-link {
	padding: 6px;
	margin-top: -9px;
	& :hover {
	color: $link-hover-color !important;
	text-decoration: underline;
	}
}

.fa-arrow-down {
	position: relative;
	bottom: 3px;
	font-size: $small-font-size;
	color: $link-color !important;
	padding: 0; 
}

#results-container {
  list-style: none;
  margin: 0;

  h3 {
    margin: 0;
    font-size: ($base-font-size *1.2);
    }
  
  li {
     margin-top: 24px;
    }
  
  li p {
    font-size: $base-font-size;
    }
}

// Searchform
 .searchform {
  margin: 0;
  background-color: $button-grey;
  max-width: 100%;
  border: 0;
  border-radius: $base-radius;
  padding: 8px;
  outline: 0;
}
 
.search-row input {
  width: 100%;
  margin: 0;
  background-color: $background-color;
  padding: 0 8px;
  border: 0;
  border-radius: $narrow-radius;
  font: inherit;
  color: $brand-color;
  line-height: 2.4;
  outline: 0;
  box-sizing: border-box;
}

// Contact Form
.contactform {
	margin-bottom: 8px;
	background-color: $button-grey;
  max-width: 100%;
  border: 0;
  border-radius: $base-radius;
  padding: 8px;
  outline: 0;
  
    input, textarea {
	    width: 100%;
	    padding: 8px;
	    line-height: 2.4;
	    background-color: $background-color;
	    border: 0;
	    border-radius: $narrow-radius;
	    font: inherit;
	    color: $brand-color;
	    box-sizing: border-box;
	    outline: 0;
    }
    
    input {
      margin-bottom: 8px;
    }
    
    textarea {
	    height: 192px;
    }
}

// Buttons
button {
  display: inline-block;
  height: 36px;
  padding: 0 16px;
  margin: 0 8px 6px 0;
  color: $brand-color;
  font: inherit;
  line-height: $base-line-height;
  background-color: $button-grey;
  border-radius: $base-radius;
  border: 0;
  outline: 0;
    &:hover {
      background-color: $button-grey-hover;
      cursor: pointer;
    }
      i {
        margin-right: 12px;
        }  
}

button.small {
  display: inline-block;
  height: 28px;
  padding: 0 8px;
  margin: 0 2px 6px 0;
  color: $brand-color;
  font-size: $small-font-size;
  line-height: $base-line-height;
  background-color: $button-grey;
  border-radius: $narrow-radius;
  border: 0;
  outline: 0;
    &:hover {
      background-color: $button-grey-hover;
      cursor: pointer;
    }
      i {
        margin-right: 0;
        }
}

button.dark {
  background-color: $button-dark;
  color: $lm-background-color;
    &:hover {
      background-color: $button-dark-hover;
    }
}

.nobutton, .nobutton-pagination {
  display: inline-block;
  height: 28px;
  padding: 0 8px;
  margin: 0 2px 6px 0;
  font-family: $base-font;
  color: $brand-color;
  font-size: $small-font-size;
  line-height: $base-line-height;
  background-color: $button-grey;
  border-radius: $narrow-radius;
  border: 0;
  outline: 0;
     &:hover {
      background-color: $button-grey;
      cursor: auto;
    }
}

.nobutton-pagination {
  position: relative;
  top: -4px;
  left: 0;
}

.call-to-action, .download {
  background-color: $link-color;
  
    &:hover {
      background-color: lighten($link-color, 8%);
    }
}

.feedly {
  background-color: $button-green;
  
    &:hover {
      background-color: $button-green-hover;
    }
}

.feedburner {
  background-color: $button-purple;
    
    &:hover {
      background-color: $button-purple-hover;
    }
}

.feedxml {
  background-color: $button-orange;
    
    &:hover {
      background-color: $button-orange-hover;
    }
}

.feedmail {
  background-color: $button-teal;
    
    &:hover {
      background-color: $button-teal-hover;
    }
}

.feedly,
.feedburner,
.feedxml,
.feedmail,
.download,
.call-to-action,
.feedburner .fa-fire,
.feedly .fa-rss,
.feedxml .fa-code,
.feedmail .fa-envelope-white,
.download .fa-download {
  color: $button-grey;
}

.rss-footer i {
  padding: 5px 5px 2px 0;
  color: darkorange;
}

// Home
.header-home {
  margin: 0 auto;
  max-width: 75%;
  text-align: center;
  
    li {
    margin-bottom: 24px;
    list-style-type: none;
    }
      a .fa-envelope:hover {
        background-color: transparent;
        border: 0;
        }
  }

.avatar-home {
  margin-bottom: -8px;
  height: 128px;
  width: 128px;
  }

.animation-home::before { 
        content: "desenvolvedor web"; 
        animation: animate infinite 8s; 
        color: $link-color;
        font-weight: bold;
    } 
  
    @keyframes animate {
        0% {content: "desenvolvedor web";} 
        25% {content: "administrador de sistemas";} 
        50% {content: "jornalista";} 
        75% {content: "tradutor técnico";}
    }

.home-links {
  display: flex;
}

.home-posts {
  flex: 1;
  order: 1;
  margin-right: $spacing-half;
}

.home-tags {
  flex: 1;
  order: 2;
  align-self: flex-start;
}

@media (max-width: $on-palm) {
  .home-links {
   flex-direction: column; }
  .home-posts { 
   padding-bottom: $spacing-half;
   margin-right: 0;
    }
  .home-tags { 
   border-top: 1px solid $border-line;
   padding-top: $spacing-unit;
    }
}

// Footer
@media screen and (min-width: $on-medium) {
  .footer-col-wrapper {
    display: flex;
  }

  .footer-col {
    width: calc(100% - (#{$spacing-unit} / 2));
    padding: 0 ($spacing-unit * .5);
    flex-grow: 1;

    &:first-child {
      padding-right: $spacing-unit * .5;
      padding-left: 0;
      text-align: left;
    }

    &:last-child {
      padding-right: 0;
      padding-left: $spacing-unit * .5;
      text-align: right;
    }
  }
}

.site-footer {
  background-color: $footer-background;
}

.social-media-list {
  display: table;
  li {
    float: left;
    margin-left: -6px;
  }
  i {
    color: $brand-color;
    font-size: 1.16em;
  }
  i:hover {
    background-color: transparent;
    color: $brand-color-dark;
  }
  a {
   padding: 8px!important;
   border: 0 !important;
   
  }
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.copy-left {
  display: inline-block;
  text-align: right;
  font-size: $base-font-size * 1.165;
  position: relative;
  bottom: -2px;
  margin: 0;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.the-end {
  width: 100%;
  margin: 0;
  text-align:center;
    &:after {
      content: "***";
      font-size: $base-font-size * 1.525;
      color: $brand-color;
      left: $spacing-unit;
      }
}

// Notes
.note {
     max-width: $on-palm;
     margin: 0 auto;
     @include media-query($on-palm) {
          max-width: 100%; }
     h1 {
       font-size: $base-font-size * 1.5!important;
     }
     h2 {
       font-size: $base-font-size * 1.2!important;
     }
}

.note-content {
     padding: $spacing-half;
     border-bottom: 1px solid $border-line;
     	h2 {
     	color: $link-color; }
}

.note-link {
     text-decoration: none;
     color: $text-color!important;
	&:hover {
	text-decoration: none;
	color: $text-color; }
	h2:hover {
	text-decoration: underline;
	color: $brand-color-dark; }
}

// Alerts
.attention {
display: block;
	width: auto;
	background-color: gold;
	border-radius: $base-radius;
	font-size: $base-font;
	text-align: center;
	padding: 8px 16px;
	& i {
	margin-right: 6px;
	}
	& a {
	color: $link-color;
	text-decoration: underline;
}
}

// NoScript Alert
.noscript-alert {
	width: 100%;
	background-color: gold;
	font-size: $small-font-size;
	color: $text-color;
	text-align: center;
	padding: 12px;
}

.noscript-alert a {
	color: $link-color;
	text-decoration: underline;
}

// Ads
.ad-bottom {
  margin-top: $spacing-unit;
}
 
.ad {
/*  margin: $spacing-unit auto;
  width: 100%;
  min-height: 170px;
  background: url(/assets/img/ad-sign.png); */
  background-repeat: no-repeat;
}

ins.adsbygoogle[data-ad-status='unfilled'] {
  display: none!important;
  height: 0!important;
  margin: 0!important;
}

/* Chrome 
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .ad,
  .ad-top,
  .ad-bottom {
    display: none;
  } 
}

/* Firefox
@-moz-document url-prefix() {
  .ad,
  .ad-top,
  .ad-bottom {
    display: none;
  }
}
*/
